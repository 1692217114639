import React, { useMemo, useState } from "react"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { BASE_API_URL } from '../../utils/constants';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Home = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [reportType, setReportType] = useState('METRICS');
    const [analytics, setAnalytics] = useState([]);
    const [trackingLogins, setTrackingLogins] = useState([]);
    const [trackingLibraryLoadingTime, setTrackingLibraryLoadingTime] = useState([]);
    const [trackingSessionDownloadTime, setTrackingSessionDownloadTime] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [neuroglympseTracking, setNeuroglympseTracking] = useState([]);

    const [isFirstLoadAnalytics, setIsFirstLoadAnalytics] = useState(false);
    const [isFirstLoadTrackingLogin, setIsFirstLoadTrackingLogin] = useState(false);
    const [isFirstLoadTrackingLibraryLoadingTime, setIsFirstLoadTrackingLibraryLoadingTime] = useState(false);
    const [isFirstLoadTrackingSessionDownloadTime, setIsFirstLoadTrackingSessionDownloadTime] = useState(false);
    const [isFirstLoadMetrics, setIsFirstLoadMetrics] = useState(false);
    const [isFirstLoadNeuroglympseTracking, setIsFirstLoadNeuroglympseTracking] = useState(false);

    const ANALYTICS_URL = BASE_API_URL + "/account/analytics";
    const TRACKING_LOGIN_URL = BASE_API_URL + "/account/total-tracking";
    const TRACKING_URL = BASE_API_URL + "/account/total-tracking-type";
    const METRICS_URL = BASE_API_URL + "/estore-metrics";
    const TOTAL_TRACKING_URL = BASE_API_URL + "/rhythmedic-total-tracking";

    const metricsColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 300
        },
        {
            field: 'landingPage',
            headerName: 'Landing Page',
            sortable: true,
            width: 300
        },
        {
            field: 'activate',
            headerName: 'Activate',
            sortable: true,
            width: 300
        },
        {
            field: 'signup',
            headerName: 'Sign Up',
            sortable: true,
            width: 300
        },
        {
            field: 'store',
            headerName: 'Store',
            sortable: true,
            width: 300
        }
    ]

    const analyticsColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 150,
            valueGetter: (value, row) => {
                return value.row.date.substring(0, 11)
            },
        },
        {
            field: 'uniqueLibraryUsers',
            headerName: 'Unique Library Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgLibrary',
            headerName: 'Library screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxLibrary',
            headerName: 'Library Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minLibrary',
            headerName: 'Library Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueMyMomentUsers',
            headerName: 'Unique My Moment Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgMyMoment',
            headerName: 'My Moment screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxMyMoment',
            headerName: 'My Moment Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minMyMoment',
            headerName: 'My Moment Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueMyInsightsUsers',
            headerName: 'Unique My Insights Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgMyInsights',
            headerName: 'Insights screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxMyInsights',
            headerName: 'My Insights Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minMyInsights',
            headerName: 'My Insights Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueJourneyStageUsers',
            headerName: 'Unique Journey Stage Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgJourneyStage',
            headerName: 'Journey screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxJourneyStage',
            headerName: 'Journey Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minJourneyStage',
            headerName: 'Journey Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueSelfGuidedUsers',
            headerName: 'Unique Self Guided Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgSelfGuided',
            headerName: 'Self-Guided screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxSelfGuided',
            headerName: 'Self Guided Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minSelfGuided',
            headerName: 'Self Guided Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueSnapshotUsers',
            headerName: 'Unique Snapshot Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgSnapshot',
            headerName: 'Snapshot screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxSnapshot',
            headerName: 'Snapshot Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minSnapshot',
            headerName: 'Snapshot Min time',
            sortable: true,
            width: 150
        },
        {
            field: 'uniqueScalesUsers',
            headerName: 'Unique Scales Users',
            sortable: true,
            width: 150
        },
        {
            field: 'avgScales',
            headerName: 'Scale screen time',
            sortable: true,
            width: 150
        },
        {
            field: 'maxScales',
            headerName: 'Scales Max time',
            sortable: true,
            width: 150
        },
        {
            field: 'minScales',
            headerName: 'Scales Min time',
            sortable: true,
            width: 150
        },
    ]

    const trackingLoginColumns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 300
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 300
        }
    ]

    const trackingLibraryLoadingTimeColumns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 300
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 300
        }
    ]

    const trackingSessionDownloadTimeColumns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 150
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 150
        },
        {
            field: 'screen',
            headerName: 'Screen',
            sortable: true,
            width: 300
        }
    ]

    const neuroglymseTrackingColumns = [
        {
            field: 'month',
            headerName: 'Month',
            sortable: true,
            width: 200,
            valueGetter: (value, row) => {
                let month = value.row.month.replace('-', '');
                return month.substring(0, 6)
            },
        },
        {
            field: 'uniqueNumber',
            headerName: 'Number',
            sortable: true,
            width: 150
        },
        {
            field: 'userIds',
            headerName: 'User IDs',
            sortable: true,
            width: 1000
        }
    ]

    const getAnalytics = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(ANALYTICS_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setAnalytics(data);
        } else {
            toast.error("GET ANALYTICS FAILED")
        }
    }

    const getTrackingLogin = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_LOGIN_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingLogins(data);
        } else {
            toast.error("GET TRACKING LOGIN FAILED")
        }
    }

    const getTrackingLibraryLoadingTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=LIBRARY_LOADING_TIME", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingLibraryLoadingTime(data);
        } else {
            toast.error("GET TRACKING LIBRARY LOADING TIME FAILED")
        }
    }

    const getTrackingSessionDownloadTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=SESSION_DOWNLOAD_TIME", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingSessionDownloadTime(data);
        } else {
            toast.error("GET TRACKING SESSION DOWNLOAD TIME FAILED")
        }
    }

    const getMetrics = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(METRICS_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setMetrics(data);
        } else {
            toast.error("GET METRICS FAILED")
        }
    }


    const getNeuroglymseTracking = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TOTAL_TRACKING_URL, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setNeuroglympseTracking(data);
        } else {
            toast.error("GET TOTAL TRACKING FAILED")
        }
    }

    useMemo(() => {
        const token = localStorage.getItem("auth");
        let time = Date.now();
        const lastTimeLogin = + localStorage.getItem("time");

        if (!token || time - lastTimeLogin > 60 * 60 * 1000) {
            localStorage.removeItem("auth");
            window.location.href = "/apidata";
        } else {
            getMetrics();
        }
    }, []);

    if (isFetching) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    const handleChangeReportType = (event) => {
        let type = event.target.value;

        if (type == 'ANALYTICS_REPORT') {
            if (!isFirstLoadAnalytics) {
                getAnalytics();
            }
            setIsFirstLoadAnalytics(true);
        }

        if (type == 'NEUROGLYMPSE_REPORT') {
            if (!isFirstLoadNeuroglympseTracking) {
                getNeuroglymseTracking();
            }
            setIsFirstLoadNeuroglympseTracking(true);
        }

        if (type == 'TRACKING_LOGIN') {
            if (!isFirstLoadTrackingLogin) {
                getTrackingLogin();
            }
            setIsFirstLoadTrackingLogin(true);
        }

        if (type == 'TRACKING_LIBRARY_LOADING_TIME') {
            if (!isFirstLoadTrackingLibraryLoadingTime) {
                getTrackingLibraryLoadingTime();
            }
            setIsFirstLoadTrackingLibraryLoadingTime(true);
        }

        if (type == 'TRACKING_DOWNLOAD_SESSION_TIME') {
            if (!isFirstLoadTrackingSessionDownloadTime) {
                getTrackingSessionDownloadTime();
            }
            setIsFirstLoadTrackingSessionDownloadTime(true);
        }

        setReportType(type);
    };

    return (
        <div className="home">
            <Toolbar>
                <Select
                    value={reportType}
                    onChange={handleChangeReportType}
                >
                    <MenuItem value={'METRICS'}>METRICS</MenuItem>
                    <MenuItem value={'ANALYTICS_REPORT'}>ANALYTICS REPORT</MenuItem>
                    <MenuItem value={'TRACKING_LOGIN'}>TRACKING LOGIN</MenuItem>
                    <MenuItem value={'TRACKING_LIBRARY_LOADING_TIME'}>TRACKING LIBRARY LOADING TIME</MenuItem>
                    <MenuItem value={'TRACKING_DOWNLOAD_SESSION_TIME'}>TRACKING DOWNLOAD SESSION TIME</MenuItem>
                    <MenuItem value={'NEUROGLYMPSE_REPORT'}>NEUROGLYMPSE REPORT</MenuItem>
                </Select>
            </Toolbar>
            <br></br>
            {reportType == 'ANALYTICS_REPORT' ?
                <>
                    <div>
                        <div style={{ textAlign: 'center' }}> ANALYTICS REPORT (Units: seconds (mean across all users)) </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={analytics}
                                columns={analyticsColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'date', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                    </div></> : null}

            {reportType == 'TRACKING_LOGIN' ?
                <>
                    <div>
                        <div style={{ textAlign: 'center' }}> TRACKING LOGIN (Via login by email only) </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={trackingLogins}
                                columns={trackingLoginColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'startTime', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                    </div></> : null}

            {reportType == 'TRACKING_LIBRARY_LOADING_TIME' ?
                <>
                    <div>  
                        <div style={{ textAlign: 'center' }}> TRACKING LIBRARY LOADING TIME </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={trackingLibraryLoadingTime}
                                columns={trackingLibraryLoadingTimeColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'startTime', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                    </div></> : null}    

            {reportType == 'TRACKING_DOWNLOAD_SESSION_TIME' ?
                <>
                    <div>                      
                        <div style={{ textAlign: 'center' }}> TRACKING DOWNLOAD SESSION TIME </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={trackingSessionDownloadTime}
                                columns={trackingSessionDownloadTimeColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'startTime', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                </div></> : null}    

            {reportType == 'METRICS' ?
                <>
                    <div>
                        <div style={{ textAlign: 'center' }}> METRICS ESTORE (Number of actions in each page per day) </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={metrics}
                                columns={metricsColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'date', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                    </div></> : null}


            {reportType == 'NEUROGLYMPSE_REPORT' ?
                <>
                    <div>
                        <div style={{ textAlign: 'center' }}> TRACKING UUIDs </div>
                        <br></br>
                        <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                            <DataGrid
                                rows={neuroglympseTracking}
                                columns={neuroglymseTrackingColumns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'month', sort: 'desc' }],
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }} />
                        </div>
                        <br></br>
                    </div></> : null}

            <ToastContainer />
        </div>
    )
}
export default Home;
